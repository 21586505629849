@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import "react-big-calendar/lib/css/react-big-calendar.css";

body {
  font-family: 'Poppins', sans-serif;
}

* {
    font-family: 'Poppins', sans-serif;
}
